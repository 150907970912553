import { AppRoute as Route } from "../../config";
import { Link, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadMedia } from "../../utils";
import { Next as NextIcon } from "../UI/Icons"

import headphone from "../../assets/images/ic-headphone.svg";
import signLanguage from "../../assets/images/ic-sign-language.svg";

function TourItem(props) {
  const { t, i18n } = useTranslation();

  const path = props.tour.pois.length === 1 ? generatePath(Route.Poi, { tourId: props.tour.id, poiId: props.tour.pois[0].id}) : generatePath(Route.Tour, { tourId: props.tour.id });

  return (
    <div className="col-lg-4">
      <Link to={path}>
        <div className={`tour-item tour-${props.tour.id}`}>
          <div className="header">
            <img
              src={loadMedia(props.tour.image)}
              width="100%"
              alt={props.tour.name[i18n.resolvedLanguage]}
            />
            <img
              className="headphone"
              src={props.tour.signLanguage ? signLanguage : headphone}
              width="100%"
              title={props.tour.signLanguage ? t("tour.signLanguage") : t("tour.audioguide")}
              alt={props.tour.signLanguage ? t("tour.signLanguage") : t("tour.audioguide")}
            />
            <img
              className="character"
              src={loadMedia(props.tour.character.image)}
              alt={props.tour.character.name}
            />
          </div>
          <div className="description">
            <h2>{props.tour.name[i18n.resolvedLanguage]}</h2>
            <span className="next">
              <NextIcon />
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default TourItem;