import { useTranslation } from "react-i18next";
import { 
  Document as DefaultIcon, 
  Image as ImageIcon, 
  Audio as AudioIcon, 
  Video as VideoIcon,
  NextAlt as NextIcon 
} from "../../UI/Icons";

function DocumentItem(props) {
  const { i18n } = useTranslation();

  return (

      <div className="col-12 doc" onClick={props.onDocumentClick}>
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: props.document.name[i18n.resolvedLanguage],
          }}
        ></div>
        <div className="doc-type">
          {props.document.type === "document" && <DefaultIcon />}
          {props.document.type === "image" && <ImageIcon />}
          {props.document.type === "audio" && <AudioIcon />}
          {props.document.type === "video" && <VideoIcon />}
          <span>{props.document.type}</span>
        </div>
        <span className="next"><NextIcon /></span>
      </div>
  );
}

export default DocumentItem;