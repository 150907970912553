import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { loadMedia } from "../../../utils";
import DocumentItem from "./DocumentItem";

function Documents(props) {
  const { i18n } = useTranslation();
  const [documentSelected, setDocumentSelected] = useState(null);

  return (
    <>
      <div className="documents-list">
        {props.documents.map((document, index) => {
          return <DocumentItem key={`doc-${index}`} document={document} onDocumentClick={() => setDocumentSelected(document)}/>;
        })}
      </div>
      {documentSelected &&
        <Modal
          id="carousel-modal"
          show={documentSelected !== null}
          onHide={() => setDocumentSelected(null)}
          centered={true}
        >
          <Modal.Body>
            <img src={
              loadMedia(
                  typeof documentSelected.content === 'string' || documentSelected.content instanceof String ? 
                    documentSelected.content : documentSelected.content[i18n.resolvedLanguage]
                )
              } 
              width="100%" 
              alt="" 
            />
            <p className="caption">
              {documentSelected.name[i18n.resolvedLanguage]}
              {documentSelected.caption &&
                <>
                  <br />
                  <small dangerouslySetInnerHTML={{__html: 
                    typeof documentSelected.caption === 'string' || documentSelected.caption instanceof String ? 
                      documentSelected.caption : documentSelected.caption[i18n.resolvedLanguage]
                  }}>
                  </small>
                </>
              }
            </p>
          </Modal.Body>
        </Modal>
      }
    </>
  );
}

export default Documents;
