import { AppRoute as Route } from "../../config";
import { useState, useRef, useEffect } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getNextPoi, getPreviousPoi } from "../../utils/JsonParser";
import { loadMedia, formatTime } from "../../utils";
import { PoiAudioPlay, PoiAudioPause, PoiAudioListened } from "../../analytics";
import { Play as PlayIcon, Pause as PauseIcon } from "../UI/Icons";

function PlayerVideo(props) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [duration, setDuration] = useState(formatTime(0));
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [previousPoi, setPreviousPoi] = useState(null);
  const [nextPoi, setNextPoi] = useState(null);
  const [analyticsItem, setAnalyticsItem] = useState({});
  const currentTimeRef = useRef(null);
  const seekRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    setAnalyticsItem({
      id: props.poi.id,
      name: `${i18n.resolvedLanguage}-${props.poi.name[i18n.resolvedLanguage]}`
    });
    setPreviousPoi(getPreviousPoi(props.tour.id, props.poi.id));
    setNextPoi(getNextPoi(props.tour.id, props.poi.id));
    resetVideo();
  }, [props.tour, props.poi, i18n.resolvedLanguage]);

  useEffect(() => {
    props.containerRef.current.appendChild(videoRef.current);
  }, [props.containerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToPreviousPoi = () => {
    navigate(generatePath(Route.Poi, { tourId: props.tour.id, poiId: previousPoi.id }));
  }

  const goToNextPoi = () => {
    navigate(generatePath(Route.Poi, { tourId: props.tour.id, poiId: nextPoi.id }));
  }

  const initializeVideo = () => {
    const duration = videoRef.current.duration;
    const videoDuration = (isNaN(duration) || duration === Infinity) ? 
      props.poi.length[i18n.resolvedLanguage] : Math.round(duration);
    seekRef.current.setAttribute('max', videoDuration);
    setDuration(formatTime(videoDuration));
  }

  const resetVideo = () => {
    currentTimeRef.current.innerHTML = formatTime(0);
    seekRef.current.value = 0;
    setDuration(formatTime(0));
    setIsPlaying(false);
    videoRef.current.load();
  }

  const togglePlay = () => {
    if (videoRef.current.paused || videoRef.current.ended) {
      setTimeout(() => videoRef.current.play(), 100);
      // videoRef.current.play();
      PoiAudioPlay(analyticsItem);
    } else {
      videoRef.current.pause();
      PoiAudioPause(analyticsItem);
    }
  }

  const playerStateDidUpdate = () => {
    setIsPlaying(!videoRef.current.paused);
  }

  const updateProgress = () => {
    seekRef.current.value = videoRef.current.currentTime;
    currentTimeRef.current.innerHTML = formatTime(videoRef.current.currentTime);
  }

  const skipAhead = (event) => {
    const skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
    videoRef.current.currentTime = skipTo;
    seekRef.current.value = skipTo;
  }

  const skipAheadDidBegin = () => {
    if (!videoRef.current.paused) {
      setWasPlaying(true);
      videoRef.current.pause();
    }
  }

  const skipAheadDidFinish = () => {
    if (wasPlaying) {
      videoRef.current.play();
      setWasPlaying(false);
    }
  }

  const videoDidFinish = () => {
    currentTimeRef.current.innerHTML = formatTime(0);
    /*if (AppConfig.SaveSessionProgress) {
      sessionStorage.removeItem(this.state.sessionCurrentTimeKey);
    }*/
    PoiAudioListened(analyticsItem);
  }

  return (
    <div id="player">
      <div className="info">
        <div className="hidden-md w-100">
          <div className="track">
            <h2>{`${props.poi.number}. ${props.poi.name[i18n.resolvedLanguage]}`}</h2>
          </div>
        </div>
        <div className="timeline">
          <span ref={ currentTimeRef } className="current-time">
            {formatTime(0)}
          </span>
          <input
            ref={ seekRef }
            className="seek"
            defaultValue="0"
            min="0"
            type="range"
            step="1"
            onInput={skipAhead}
            onPointerDown={ skipAheadDidBegin }
            onPointerUp={ skipAheadDidFinish }
          />
          <span className="duration">{ duration }</span>
        </div>
      </div>
      <div className="controls">
        <video
          ref={ videoRef }
          src={loadMedia(props.poi.audio[i18n.resolvedLanguage])}
          type="video/mp4"
          title={props.poi.name[i18n.resolvedLanguage]}
          onLoadedMetadata={ initializeVideo }
          onPlay={ playerStateDidUpdate }
          onPause={ playerStateDidUpdate }
          onEnded={ videoDidFinish }
          onTimeUpdate={ updateProgress }
          controls={false}
          autoPlay={false}
          muted={false}
          loop={false}
          playsInline={true}
          poster={loadMedia(props.poi.images[0].content)}
        />
        <button
          onClick={goToPreviousPoi}
          className="previous"
          disabled={previousPoi ? false : true}
        >
          { previousPoi ? `#${previousPoi.number}` : null }
        </button>
        <button onClick={togglePlay} className="play">
          { isPlaying ? <PauseIcon /> : <PlayIcon />}
        </button>
        <button
          onClick={goToNextPoi}
          className="next"
          disabled={nextPoi ? false : true}
        >
          { nextPoi ? `#${nextPoi.number}` : null}
        </button>
      </div>
    </div>
  );
}

export default PlayerVideo;
