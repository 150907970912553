import React from 'react';
import { useTranslation } from 'react-i18next';

import appLogo from '../../assets/images/logo.svg';

function Logo() {
  const { t } = useTranslation();

  return <img src={appLogo} width="160" alt={ t("menu.logo") } />;
}

export default Logo;