import { AppRoute as Route } from "../../config";
import { generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loadMedia } from "../../utils";
import Breadcrumb from "../UI/Breadcrumb";
import Title from "../UI/Title";
import PoiItem from "../Poi/PoiItem";

function Tour(props) {
  const { i18n } = useTranslation();

  return (
    <div id="tour" className={`scroll-wrapper tour-${props.tour.id}`}>
      <Breadcrumb>
        <li>{props.tour.name[i18n.resolvedLanguage]}</li>
      </Breadcrumb>
      <Title 
        title={props.tour.name[i18n.resolvedLanguage]} 
        backLink={generatePath(Route.Home)} 
      />
      <section className="page-header">
        <img src={loadMedia(props.tour.image)} width="100%" alt={props.tour.name[i18n.resolvedLanguage]} />
        <img className="character" src={loadMedia(props.tour.character.image)} alt={props.tour.character.name} />
      </section>
      <div id="pois-list" className="container-fluid">
        {props.tour.pois.map((poi) => {
          return <PoiItem key={poi.id} poi={poi} tour={props.tour} />;
        })}
      </div>
    </div>
  )
}

export default Tour;