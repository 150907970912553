import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AppPreference } from '../config/AppPreferences';

let analytics = null;
const analyticsEnabled = process.env.NODE_ENV === 'production';
const DEFAULT_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const analyticsIsAvailable = () => {

  return analyticsEnabled && localStorage.getItem(AppPreference.CookiesConsent) === 'true';
}

export const Init = (config = DEFAULT_CONFIG) => {

  if (!analyticsIsAvailable()) return;

  const app = initializeApp(config);
  analytics = getAnalytics(app);
}

export const PoiAudioPlay = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.logEvent("poi_audio_played", item);
}

export const PoiAudioPause = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.logEvent("poi_audio_paused", item);
}

export const PoiAudioListened = (item) => {

  if (!analyticsIsAvailable() || !analytics) return;

  analytics.logEvent("poi_audio_listened", item);
}