import { useTranslation } from "react-i18next";
import TourItem from "./Tour/TourItem";

function Home(props) {
  const { t, i18n } = useTranslation();

  return (
    <div id="home" className="scroll-wrapper">
      <section className="page-header"></section>
      <div className="container-fluid">
        <h1>{t("home.title")}</h1>
        <div id="tours-list" className="row">
          {props.tours.map((tour) => {
            return tour.published[i18n.resolvedLanguage] ? <TourItem key={tour.id} tour={tour} /> : null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
